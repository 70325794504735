<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        pill
        @click="createHealthPackage"
        v-if="hasPermission('create_health_package')"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div>

    <b-card>
      <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Name"
              label-for="name"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <b-form-input id="name" v-model="name" placeholder="Name" />
            </b-form-group>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="healthPackages"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('read_health_package')"
        :per-page="0"
      >
        <template #custom-foot>
          <b-tr variant="light">
            <b-td :colspan="fields.length">
              <span class="font-weight-bolder">
                Total Rows: {{ totalItems }}
              </span>
            </b-td>
          </b-tr>
        </template>
        <template #cell(serial)="row">
          {{ (currentPage - 1) * perPage + row.index + 1 }}
        </template>
        <template #cell(name)="row">
          {{ truncate(row.item.name, 20) }}
        </template>
        <template #cell(location)="row">
          {{ truncate(row.item.location, 20) }}
        </template>
        <template #cell(Packages_company)="row">
          {{ truncate(row.item.Packages_company_name, 20) }}
        </template>

        <template #cell(created_at)="row">
          {{ row.item.created_at }}
        </template>
        <template #cell(updated_at)="row">
          {{ row.item.updated_at }}
        </template>
        <template #cell(created_by_data)="row">
          {{ row.item.created_by }}
        </template>
        <template #cell(updated_by_data)="row">
          {{ row.item.updated_by }}
        </template>
        <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="editHealthPackage(row.item)"
            v-if="hasPermission('update_health_package')"
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="removeHealthPackage(row.item)"
            v-if="hasPermission('delete_health_package')"
          >
            Delete
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
      <!-- <span class="mt-1 bg1 float-right" >Total Rows: {{ rows }}</span> -->
    </b-card>
    <health-packages-create-modal
      @modalClosed="onModalClosed"
      :key="`create-${healthPackagesCreateModalCount}`"
    />
    <health-packages-edit-modal
      :healthPackage="healthPackage"
      @modalClosed="onModalClosed"
      :key="`edit-${healthPackagesEditModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import HealthPackagesCreateModal from "@/components/admin/packages/HealthPackagesCreateModal.vue";
import HealthPackagesEditModal from "@/components/admin/packages/HealthPackagesEditModal.vue";

export default {
  components: {
    HealthPackagesCreateModal,
    HealthPackagesEditModal,
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "name", label: "Name" },
        { key: "amount", label: "Amount" },
        { key: "created_at", label: "Created At" },
        { key: "created_by_data", label: "Created By" },
        { key: "updated_at", label: "Created At" },
        { key: "updated_by_data", label: "Updated By" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      healthPackages: [],
      healthPackage: null,
      healthPackagesCreateModal: 0,
      healthPackagesEditModal: 0,
      healthPackagesCreateModalCount: 0,
      healthPackagesEditModalCount: 0,
      nameFilter: "",
      searchTypes: [{ value: 1, name: "Name" }],
      searchType: null,
      name: "",
    };
  },
  mixins: [util],
  async mounted() {
    await this.fetchPaginatedData();
  },
  created() {
    this.searchType = this.searchTypes[0];
  },
  methods: {
    ...mapActions({
      getHealthPackages: "appData/getHealthPackages",
      deleteHealthPackage: "appData/deleteHealthPackage",
    }),
    async search() {
      if (this.searchType) {
        switch (this.searchType.value) {
          case 1:
            break;
        }
      } else {
        this.name = "";
      }
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },

    async fetchPaginatedData() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
        };
        if (this.name) {
          tempParams["name"] = this.name;
        }
        const res = await this.getHealthPackages(tempParams);
        this.healthPackages = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },

    createHealthPackage() {
      this.healthPackagesCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("health-packages-create-modal");
      });
    },
    editHealthPackage(healthPackage) {
      this.healthPackage = healthPackage;
      this.healthPackagesEditModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("health-packages-edit-modal");
      });
    },
    async removeHealthPackage(healthPackage) {
      this.healthPackage = healthPackage;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteHealthPackage({
              pk: this.healthPackage.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: "Health Package deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            this.show = false;
            this.displayError(error);
          }
        } else {
          this.show = false;
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
    rows() {
      return this.healthPackages.length;
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
    searchType: function (newVal, oldVal) {
      if (newVal && oldVal && newVal.value !== oldVal.value) {
        this.name = "";
      }
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>
