<template>
  <b-modal
    id="health-packages-edit-modal"
    title="Interprator Service Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Service Edit</h2>
    </template>
    <validation-observer ref="healthPackageEditFormValidation">
      <b-form @submit.prevent="validationForm">
        <b-form-group label-for="name">
          <template #label>
            Package Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            placeholder="Package Name"
            @keydown="checkMaxLength($event, 'name', 200)"
            required
          />
        </b-form-group>

        <b-form-group label-for="description">
          <template #label> Description </template>
          <quill-editor id="description" v-model="description" />
        </b-form-group>

        <b-form-group label-for="amount">
          <template #label>
            Amount <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="amount"
            v-model="amount"
            placeholder="Amount"
            type="number"
            min="1"
            step="1"
            required
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group label-for="image">
          <template #label> Image <span class="text-danger">*</span> </template>
          <div v-if="files" class="mb-2">
            <img
              :src="files"
              alt="Blog Image"
              class="img-thumbnail"
              style="max-width: 15%; margin-bottom: 10px"
            />
          </div>
          <validation-provider v-slot="{ errors }" name="Image">
            <b-form-group
              class="form-group"
              id="input-group-2"
              label-for="input-2"
              description="Upload an image in PNG, JPG, or JPEG format."
              :invalid-feedback="errors[0]"
            >
              <b-form-file
                ref="imageInput"
                id="documents"
                placeholder="Choose a PNG, JPG, or JPEG file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".png,.jpg,.jpeg"
                plain
                v-model="files"
                @change="validateFileType($event, 'files')"
              ></b-form-file>
            </b-form-group>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="primary" pill class="mr-1">
            Save
          </b-button>
          <b-button
            type="reset"
            variant="outline-secondary"
            @click="reset"
            pill
          >
            Reset
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [util],
  props: {
    healthPackage: Object,
  },
  data() {
    return {
      required,
      name: "",
      description: "",
      amount: "",
      files: null,
      status: "",
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  async mounted() {
    if (this.healthPackage) {
      this.name = this.healthPackage.name;
      this.description = this.healthPackage.description;
      this.amount = this.healthPackage.amount;
      this.status = this.healthPackage.status;
      this.files = this.healthPackage.display_image;
    }
  },
  methods: {
    ...mapActions({
      updateHealthPackage: "appData/updateHealthPackage",
    }),
    async validationForm() {
      const success =
        await this.$refs.healthPackageEditFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    validateFileType(event) {
      const file = event.target.files[0];
      if (!file) return;

      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        event.target.value = null;
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity(
            "Please upload a PNG, JPG, or JPEG image file."
          );
          this.$refs.imageInput.$el.reportValidity();
        }
      } else {
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity("");
        }
      }
    },
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async submit() {
      const formData = new FormData();

      formData.append("name", this.name);
      formData.append("description", this.description);
      formData.append("amount", this.amount);
      formData.append("status", this.status);
      if (this.files instanceof File) {
        formData.append("display_image", this.files);
      }
      formData.append("updated_by", this.getLoggedInUser.id);

      try {
        const res = await this.updateHealthPackage({
          payload: formData,
          pk: this.healthPackage.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Health Package updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("health-packages-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.description = "";
      this.amount = "";
      this.files = null;
      this.status = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    amountState() {
      return (
        this.amount !== "" &&
        Number.isInteger(Number(this.amount)) &&
        Number(this.amount) > 0
      );
    },
  },
};
</script>

<style></style>
